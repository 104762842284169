import { useZxing } from "react-zxing";
import { useState } from "react";
import { Rings } from "react-loader-spinner"
import useSound from 'use-sound';
import beep from './beep.mp3';
import {NotificationManager} from 'react-notifications';


function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

const hostName = (process.env.NODE_ENV === 'development' )? 'http://localhost:8081' : ''

const scanNames = ['Scan teamlead', 'Scan lotID', 'Scan worker', 'Ready to dispatch', 'Dispatching']
function Dispatch(props) {
  const [scanState, setScanState] = useState(0)
  const [scanSuccess, setScanSuccess] = useState(0)
  const [scanLock, setScanLock] = useState(0)
  const [teamlead, setTeamlead] = useState()
  const [process, setProcess] = useState()
  const [worker, setWorker] = useState()
  const [play] = useSound(beep);

  const { ref } = useZxing({
    onDecodeResult(result) {
      if (scanLock === 0) {
        setScanLock(1)
      } else {
        return
      }
      setScanSuccess(3)
      if (scanState === 0) {
        // getRecourse
        play()
        teamleadInput(parseInt(result.getText()))
      }
      if (scanState === 1) {
        // getProcess
        play()
        const [lotId, subnr] = result.getText().split('-')
        processInput(lotId, subnr)
      }
      if (scanState === 2) {
        // getRecource
        play()
        workerInput(parseInt(result.getText()))
      }
    },
  })

  const teamleadInput = async (seqres) => {
    try {
      const response = await fetch(`${hostName}/v1/factory/resource/${seqres}`)
      const resource = await response.json()
      setScanSuccess(1)
      setTeamlead(resource)
      setScanState(1)
    } catch (error) {
      setScanSuccess(0)
    }
    await timeout(1500);
    setScanLock(0)
  }

  const processInput = async (lotId, subnr) => {
    try {
      const response = await fetch(`${hostName}/v1/factory/process/${lotId}/${subnr}`)
      const process = await response.json()
      setScanSuccess(1)
      setProcess(process)
      setScanState(2)
    } catch (error) {
      setScanSuccess(0)
    }
    await timeout(1500);
    setScanLock(0)
  }

  const workerInput = async (seqres) => {
    try {
      const response = await fetch(`${hostName}/v1/factory/resource/${seqres}`)
      const resource = await response.json()
      setScanSuccess(1)
      setWorker(resource)
      setScanState(3)
    } catch (error) {
      setScanSuccess(0)
    }
    await timeout(1500);
    setScanLock(0)
  }

  const dispatchProcess = async () => {
    try {
      setScanState(4)
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Cot-Factory-User': teamlead.seqres 
        },
        body: JSON.stringify({
          seqresWorker: worker.seqres,
          seqresTeamlead: teamlead.seqres,
          timeStamp: (new Date()).toISOString()
        })
      };
      const res = await fetch(`${hostName}/v1/factory/process/dispatch/${process.lotId}/${process.subnr}`, requestOptions)
      if (res.status !== 200) {
        const response = await res.json()
        NotificationManager.error('Error', response.message, 4000);
        setScanState(3)
      } else {
        props.goToMain()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const removeProcess = () => {
    setProcess()
    setWorker()
    setScanState(1)
  }

  let scanWhoWhat = scanNames[scanState]
  return (
    <div className="container">
      <button className='backButton' onClick={props.goToMain}>&lt;</button>
      <h3>{scanWhoWhat}</h3>
      <div className={`videoBox ${scanSuccess === 1 && scanLock ? 'scanSuccess' : ''} ${scanSuccess === 0 && scanLock ? 'scanFail' : ''}  ${scanSuccess === 3 && scanLock ? 'scanning' : ''}`}>
        <video ref={ref}/>
      </div>
      <table>
        <tbody>
          <tr>
            <th>Teamlead</th>
            <td>{teamlead ? `${teamlead.id} - ${teamlead.achternaam} ${teamlead.voornaam}` : ''}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          {process ? (
            <tr >
            <td colSpan="2">
              <button onClick={removeProcess}>Clear</button>
            </td>
          </tr>
          ) : null}
          <tr>
            <th>LotId</th>
            <td>{process ? `${process.lotId} - ${process.subnr} ` : ''}</td>
          </tr>
          <tr>
            <th>Cts</th>
            <td>{process ? `${process.cts} ` : ''}</td>
          </tr>
          <tr>
            <th>Pcs</th>
            <td>{process ? `${process.pcs} ` : ''}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <th>Worker</th>
            <td>{worker ? `${worker.id} - ${worker.achternaam} ${worker.voornaam}` : ''}</td>
          </tr>
        </tbody>
      </table>
      <button className='mainButton' disabled={(scanState !== 3)} onClick={dispatchProcess}> {
        scanState === 4 ? (
          <Rings
            height="40"
            width="40"
            color="white"
            radius="2"
            wrapperStyle={{ display: "inline" }}
            visible={true}
            ariaLabel="rings-loading"
          />
        ) : 'DISPATCH'
      }</button>
    </div>
  )

}

export default Dispatch;