import { useZxing } from "react-zxing";
import { useState } from "react";
import { Rings } from "react-loader-spinner"
import useSound from 'use-sound';
import beep from './beep.mp3';
import { NotificationManager } from 'react-notifications';

function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}

const hostName = (process.env.NODE_ENV === 'development') ? 'http://localhost:8081' : ''

const scanNames = ['Scan teamlead', 'Scan lotID', 'Pieces returned', 'Ready to receive', 'Receiving']
function Receive(props) {
  const [scanState, setScanState] = useState(0)
  const [scanSuccess, setScanSuccess] = useState(0)
  const [scanLock, setScanLock] = useState(0)
  const [teamlead, setTeamlead] = useState()
  const [process, setProcess] = useState()
  const [pieces, setPieces] = useState()
  const [mismatchPieces, setMismatchPieces] = useState(0)
  const [mismatchConfirmed, setMismatchConfirmed] =useState(0)
  const [play] = useSound(beep);

  const { ref } = useZxing({
    onDecodeResult(result) {
      if (scanLock === 0) {
        setScanLock(1)
      } else {
        return
      }
      setScanSuccess(3)
      if (scanState === 0) {
        // getRecourse
        play()
        teamleadInput(parseInt(result.getText()))
      }
      if (scanState === 1) {
        // getProcess
        play()
        const [lotId, subnr] = result.getText().split('-')
        processInput(lotId, subnr)
      }
    },
  })

  const teamleadInput = async (seqres) => {
    try {
      const response = await fetch(`${hostName}/v1/factory/resource/${seqres}`)
      const resource = await response.json()
      setScanSuccess(1)
      setTeamlead(resource)
      setScanState(1)
    } catch (error) {
      setScanSuccess(0)
    }
    await timeout(1500);
    setScanLock(0)
  }

  const processInput = async (lotId, subnr) => {
    try {
      const response = await fetch(`${hostName}/v1/factory/process/${lotId}/${subnr}`)
      const process = await response.json()
      setScanSuccess(1)
      setProcess(process)
      setScanState(2)
    } catch (error) {
      setScanSuccess(0)
    }
    await timeout(1500);
    setScanLock(0)
  }

  const receiveProcess = async () => {
    try {
      setScanState(3)
      const confirmed = mismatchConfirmed ? true : undefined
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Cot-Factory-User': teamlead.seqres 
        },
        body: JSON.stringify({
          seqresTeamlead: teamlead.seqres,
          receivedPieces: pieces,
          confirmed,
          timeStamp: (new Date()).toISOString()
        })
      };
      const res = await fetch(`${hostName}/v1/factory/process/receive/${process.lotId}/${process.subnr}`, requestOptions)
      if (res.status !== 200) {
        const response = await res.json()
        NotificationManager.error('Error', response.message, 4000);
        setScanState(3)
      } else {
        props.goToMain()
      }
    } catch (error) {

    }
  }

  const confirmMismatch = () => {
    setMismatchConfirmed(1)
  }

  const piecesInputChange = (event) => {
    setMismatchConfirmed(0)
    let value = event.target.value.replace(/\D/g, '')
    value = parseInt(value)
    if (!isNaN(value)) {
      if (process.seqdep !== 2 && value > process.pcs) {
        NotificationManager.warning('warning', `Pieces cannot be bigger than ${process.pcs}`,1000)
        setPieces(pieces)
      } else if (process.seqdep !== 2 && value === process.pcs) {
        setMismatchPieces(0)
        setScanState(3)
        setPieces(value)
      } else if (process.seqdep !== 2 && value < process.pcs * parseFloat(process.allowed_received_pcs_pct)) {
        setMismatchPieces(2)
        setScanState(3)
        setPieces(value)
      } else if (process.seqdep !== 2 && value === 0) {
        setMismatchPieces(0)
        setScanState(2)
        setPieces(0)
      } else if (process.seqdep !== 2) {
        setMismatchPieces(1)
        setScanState(3)
        setPieces(value)
      } else {
        setMismatchPieces(0)
        setScanState(3)
        setPieces(value)
      }
    } else {
      setMismatchPieces(0)
      setScanState(2)
      setPieces(0)
    }
  }

  const removeProcess = () => {
    setProcess()
    setScanState(1)
    setMismatchPieces(0)
    setPieces(0)
    setMismatchConfirmed(0)
  }

  let scanWhoWhat = scanNames[scanState]

  return (
    <div className="container">
      <button className='backButton' onClick={props.goToMain}>&lt;</button>
      <h3>{scanWhoWhat}</h3>
      <div className={`videoBox ${scanSuccess === 1 && scanLock ? 'scanSuccess' : ''} ${scanSuccess === 0 && scanLock ? 'scanFail' : ''}  ${scanSuccess === 3 && scanLock ? 'scanning' : ''}`}>
        <video ref={ref} />
      </div>
      <table>
        <tbody>
          <tr>
            <th>Teamlead</th>
            <td>{teamlead ? `${teamlead.id} - ${teamlead.achternaam} ${teamlead.voornaam}` : ''}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          {process ? (
            <tr >
              <td colSpan="2">
                <button onClick={removeProcess}>Clear</button>
              </td>
            </tr>
          ) : null}
          <tr>
            <th>LotId</th>
            <td>{process ? `${process.lotId} - ${process.subnr} ` : ''}</td>
          </tr>
          <tr>
            <th>Cts</th>
            <td>{process ? `${process.cts} ` : ''}</td>
          </tr>
          <tr>
            <th>Pcs</th>
            <td>{process ? `${process.pcs} ` : ''}</td>
          </tr>
          <tr>
            <th>From worker</th>
            <td>{process && process.worker ? `${process.worker.id} - ${process.worker.achternaam} ${process.worker.voornaam}` : ''}</td>
          </tr>
          <tr>
            <th>Dispatched</th>
            <td>{process ? `${new Date(process.to_worker_on).toLocaleString()} ` : ''}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <th><label htmlFor="pieces">Pieces</label></th>
            <td><input disabled={scanState < 2} id="pieces" onInput={piecesInputChange} value={pieces}></input></td>
          </tr>
          {mismatchPieces > 0
            ?
            <tr >
              <td colSpan={2}>
                {mismatchPieces === 1 
                ? (
                <div className="warningBlock">Warning: Amount is lower then amount dispatched!</div>
                ) : (
                  <>
                    <div className="warningBlock">Warning: Amount is below allowed threshold!</div>
                    <button className={`confirm ${mismatchConfirmed === 0 ? 'red' : 'green'}`} onClick={confirmMismatch}>{`${mismatchConfirmed === 0 ? 'Confirm amount' : 'Amount confirmed'}`}</button>
                  </>
                )}
              </td>
            </tr>
            : null
          }
        </tbody>
      </table>
      <button className='mainButton' disabled={(scanState !== 3 || (mismatchPieces === 2 && mismatchConfirmed === 0))} onClick={receiveProcess}> {
        scanState === 4 ? (
          <Rings
            height="40"
            width="40"
            color="white"
            radius="2"
            wrapperStyle={{ display: "inline" }}
            visible={true}
            ariaLabel="rings-loading"
          />
        ) : 'Receive'
      }</button>
    </div>
  )

}

export default Receive;