import './App.css';
import { useState } from "react";
import Dispatch from './Dispatch';
import Receive from './Receive';
import CheckInOut from './CheckInOut';
import pkg from '../package.json'

const {version} = pkg


function App() {
  const [page, setPage] = useState('main')
  
  const goToDispatch = () => {
    setPage('dispatch')
  }
  const goToReceive = () => {
    setPage('receive')
  }
  const goToCheckin = () => {
    setPage('checkInOut')
  }
  const goToMain = () => {
    setPage('main')
  }
  switch (page) {
    case 'main':
      return (
          <div className="container">
            <h2>SCANNER</h2>
            <button className='mainButton' onClick={goToDispatch}>DISPATCH</button>
            <button className='mainButton' onClick={goToReceive}>RECEIVE</button>
            <button className='mainButton' onClick={goToCheckin}>CHECK IN/OUT</button>
            <br/>
            v{version}
          </div>
      )
    case 'dispatch': 
        return (
          <Dispatch goToMain={goToMain}></Dispatch>
        )
    case 'receive': 
        return (
          <Receive goToMain={goToMain}></Receive>
        )
    case 'checkInOut': 
        return (
          <CheckInOut goToMain={goToMain}></CheckInOut>
        )
    default:
      break;
  }
  
}

export default App;
