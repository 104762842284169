import { useZxing } from "react-zxing";
import { useState } from "react";
import { Rings } from "react-loader-spinner"
import useSound from 'use-sound';
import beep from './beep.mp3';
import {NotificationManager} from 'react-notifications';

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

const hostName = (process.env.NODE_ENV === 'development' )? 'http://localhost:8081' : ''
const scanNames = ['Scan worker', 'Ready for check in/out', 'Checking in', 'Checking out']

function CheckInOut (props) {
  const [play] = useSound(beep);
  const [scanState, setScanState] = useState(0)
  const [checkType, setCheckType] = useState(0)
  const [worker, setWorker] = useState()
  const [scanSuccess, setScanSuccess] = useState(0)
  const [scanLock, setScanLock] = useState(0)

  const { ref } = useZxing({
    onDecodeResult(result) {
      if (scanLock === 0) {
        setScanLock(1)
      } else {
        return
      }
      play()
      workerInput(parseInt(result.getText()))
    },
  })

  
  const workerInput = async (seqres) => {
    try {
      const response = await fetch(`${hostName}/v1/factory/resource/${seqres}`)
      const resource = await response.json()

      if (resource.openRegistration) {
        setCheckType(1)
      } else {
        setCheckType(0)
      }

      setScanSuccess(1)
      setWorker(resource)
      setScanState(1)
    } catch (error) {
      setScanSuccess(0)
    }
    await timeout(1500);
    setScanLock(0)
  }

  const checkWorkerInOut = async () =>{
    if (checkType === 0) {
      setScanState(2)
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          checkInDeviceTime: (new Date()).toISOString()
        })
      };
      const res = await fetch(`${hostName}/v1/factory/resource/checkin/${worker.seqres}`, requestOptions)
      if (res.status !== 200) {
        const response = await res.json()
        NotificationManager.error('Error', response.message, 4000);
        setScanState(1)
      } else {
        props.goToMain()
      }
    } else {
      setScanState(3)
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          checkOutDeviceTime: (new Date()).toISOString(),
          registrationId: worker.openRegistration.id
        })
      };
      const res = await fetch(`${hostName}/v1/factory/resource/checkout/${worker.seqres}`, requestOptions)
      if (res.status !== 200) {
        const response = await res.json()
        NotificationManager.error('Error', response.message, 4000);
        setScanState(1)
      } else {
        props.goToMain()
      }
    }
    
  }

  let scanWhoWhat = !worker ? 'Scan worker' : 'Check in/out'
  return (
    <div className="container">
      <button className='backButton' onClick={props.goToMain}>&lt;</button>
      <h3>{scanWhoWhat}</h3>
      <div className={`videoBox ${scanSuccess === 1 && scanLock ? 'scanSuccess' : ''} ${scanSuccess === 0 && scanLock ? 'scanFail' : ''}  ${scanSuccess === 3 && scanLock ? 'scanning' : ''}`}>
        <video ref={ref}/>
      </div>
      <table>
        <tbody>
          <tr>
            <th>Worker</th>
            <td>{worker ? `${worker.id} - ${worker.achternaam} ${worker.voornaam}` : ''}</td>
          </tr>
        </tbody>
      </table>
      <button className='mainButton' disabled={(scanState !== 1)} onClick={checkWorkerInOut}> {
        scanState === 2 || scanState === 3 ? (
          <Rings
            height="40"
            width="40"
            color="white"
            radius="2"
            wrapperStyle={{ display: "inline" }}
            visible={true}
            ariaLabel="rings-loading"
          />
        ) : `Check ${checkType === 0 ? 'in' : 'out'}`
      }</button>
     </div>
  )
}

export default CheckInOut;
