import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));

const askCameraPermission =
  async (): Promise<MediaStream | null> => await navigator.mediaDevices.getUserMedia({ video: true });

let localstream: MediaStream | null;
askCameraPermission()
  .then(response => {
    localstream = response;
  })
  .then(() => {
    localstream?.getTracks().forEach(track => {
      track.stop();
    });
  })
  .catch(error => console.log(error));
  
root.render(
  <React.StrictMode>
    <App />
    <NotificationContainer/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
